import React, { Component } from 'react';
import './Home.css';
import NavBar from '../components/NavBar';
import Transitions from './Transision.js';
import Contact from '../components/Contact';
import Copyright from '../components/Copyright';
import Spline from '@splinetool/react-spline';


  
    
  


export default function Home(){

    return(
        <Transitions>
       
        <div className='Home'>
            <NavBar/>
            <div className='HeaderText'>
                <div className='HeaderTitle'>
                    


                    
               
                <h1 className='Heading'>EDWARD BERA</h1>
                <p>Hi, I am a Full Stack Software Engineer.</p>
                </div>
            
            
           
            </div>
            <div className='header-avatar'>
              l
            </div>
            {/*<Spline className='spline-animation' scene="https://prod.spline.design/UA8z1AsPlTAFexiw/scene.splinecode" />*/}
            <Contact/>
          <Copyright/>
        </div>
        </Transitions>

    )
}