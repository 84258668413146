import React, { Component } from 'react';
import "./Card.css";
import { motion } from 'framer-motion';

export default function Card(props){

    return(
        <motion.div
        whileHover={{ scale: 1.2}}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
        <div className='Card'>
            <h1 className='Card-Title'>{props.title}</h1>
            <img className='Card-Image' src={props.url} alt={props.alt}/>
            <p className='Card-Description'>{props.description} </p>
            <a target="_blank"
   rel="noreferrer"
   href={props.link}><div className='Card-Link'>
   <p>
   Link
   </p>
    
    
    </div></a>
        </div>
        </motion.div>
    )
}