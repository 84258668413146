import { motion } from 'framer-motion';

const animationConfiguration = {
    initial: { y: 1000, opacity: 0 },
    animate: { y:0, opacity: 1 },
    exit: { opacity: 0 },
    
};
const VTransitions = ({ children }) => {
    return (
        <motion.div
            variants={animationConfiguration}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 1 }}
        >
            {children}
        </motion.div>
    );
};
export default VTransitions;