import React, { Component } from 'react';
import './NavBar.css';
import {Link, useNavigate} from 'react-router-dom';
import { motion } from 'framer-motion';
import HoverAnimation from '../Pages/HoverAnimation';


export default function NavBar() {
    


    return(
       
        <div className='NavBar'>

                <motion.div
                    whileHover={{ scale: 1.5 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                <Link className='NavItem' to={"/About"}>
               
                    ABOUT

                   
                </Link>
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.5 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                <Link className='NavItem' to={"/Projects"}>
                    PROJECTS
                </Link>
                </motion.div>
          
           
        </div>
      
    )
  
}
