import React, { Component } from 'react';
import './Copyright.css';

export default function Copyright(){


    return(
        <div className='Copyright'>
            <small>Created & Developed by Edward Bera &copy; 2023 </small>
        </div>


    )


}