import logo from './logo.svg';
import './App.css';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Home from './Pages/Home.js';
import About from './Pages/About';
import Projects from './Pages/Projects';
import { AnimatePresence } from 'framer-motion';

function App() {

  return (
    <div className="App">
      <AnimatePresence wait >
      <BrowserRouter> 
      <Routes>
      <Route path ='/' element ={<Home/>}/>
      <Route path ='/About' element ={<About/>}/>
      <Route path ='/Projects' element ={<Projects/>}/>
      </Routes>
      </BrowserRouter>
      </AnimatePresence>

    </div>
  );
}

export default App;
