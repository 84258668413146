import React, { Component } from 'react';
import './Projects.css';
import Card from '../components/Card';
import {Link, useNavigate} from 'react-router-dom';
import VTransitions from './VTransision.js';
import { motion } from 'framer-motion';
import Copyright from '../components/Copyright';

export default function Projects(){

    return(
        <VTransitions>
        <div className='Projects'>
            <div className='Projects-Nav'>
            <motion.div
                    whileHover={{ scale: 1.5 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                    
                <Link className='Projects-NavItem' to={"/"}>
                    HOME
                </Link>
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.5 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >

                <Link className='Projects-NavItem' to={"/About"}>
                    ABOUT
                </Link>
                </motion.div>
                </div>
            <h1 className='Project-Heading'>PROJECTS</h1>
                
            <div className='Project-Container'>
                <Card title ="URL Shortener" url = "https://i.ibb.co/GMKDm3r/Screenshot-2023-12-19-at-15-59-32.png" description="URL Shortener Built using React JS, Node JS & MongoDB" link="http://www.smur1.xyz"/>
                <Card title ="Todo APP" url = "https://i.ibb.co/6vqTTLg/302057181-1850822f-5c56-4a8f-a5a6-7d2e92d32929.png" description="A todo cli application build entirely in golang." link ="https://github.com/edwardbera/todo"/>
                <Card title ="Simple Notes App" url = "https://i.ibb.co/CVqnRtD/notes.png" description="A Simple note taking app that allows you to create notes, edit and delete notes." link="https://harmonious-manatee-9491cb.netlify.app/"/>
            </div>
            <Copyright/>
        </div>
        </VTransitions>
    )
}