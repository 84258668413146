import React, { Component } from 'react';
import Transitions from './Transision.js';
import VTransitions from './VTransision.js';
import './About.css';
import {Link, useNavigate} from 'react-router-dom';
import { motion } from 'framer-motion';
import Copyright from '../components/Copyright.js';

export default function About(){


    return(
        <VTransitions>
        <div className='About'>
        <div className='About-Nav'>
        <motion.div
        whileHover={{ scale: 1.5 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
                <Link className='Projects-NavItem' to={"/Projects"}>
                    PROJECTS
                </Link>
                </motion.div>
                <motion.div
        whileHover={{ scale: 1.5 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
                <Link className='Projects-NavItem' to={"/"}>
                    HOME
                </Link>
                </motion.div>
                </div>
            <div className='About-Intro'>
            
            <h1 className='About-Header'>ABOUT</h1>
            <p>Crafting digital experiences, one line of code at a time.
                 I'm a passionate software engineer with a knack for building 
                 sleek and powerful desktop and web applications. Let's turn your ideas into innovative realities together!</p>  
                 <a target="_blank"
   rel="noreferrer"
   href="https://red-lotti-17.tiiny.site"><div className='Resume-Link'>
   <p>
   OPEN RESUME
   </p>
    
    
    </div></a>
            </div>

           
       

            <div className='About-Technologies'>
                <div className='Tech-item'>
                <img className="Tech-Logo" src="https://i.ibb.co/q5fhg6c/nodejs-1-logo-png-transparent.png"  alt='Node JS Logo'/>
                </div>
                <div className='Tech-item'>
                <img className="Tech-Logo" src="https://i.ibb.co/vz7mJgd/python-logo-1.png" alt="Python Logo"/>

                </div>
                <div className='Tech-item'>
                <img className="Tech-Logo" src="https://i.ibb.co/FqX6t3J/react-logo.png" alt="React Logo" />
                </div>
                <div className='Tech-item'>
               <img className="Tech-Logo"  src="https://i.ibb.co/h2QB6XS/html-logo.png" alt="Html Logo"/>
                </div>
                <div className='Tech-item'>
                <img className="Tech-Logo" src="https://i.ibb.co/KLSWYgb/css3-logo.png" alt="Css3-Logo" />
                </div>
                <div className='Tech-item'>
                <img className="Tech-Logo" src="https://i.ibb.co/Yy89gF5/javascript-39395.png" a alt="JS-Logo" />
                </div>
            </div>
            
              <Copyright/>
        </div>
        </VTransitions>
    )
}