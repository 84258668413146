import React, { Component } from 'react';
import './Contact.css';
import { motion } from 'framer-motion';


export default function Contact(){

    return(
        <div className='Contact'>
            <motion.div
        whileHover={{ rotate: 360}}
        transition={{ type: "inertia", velocity:400 }}
    >
            <a href='https://github.com/edwardbera' target='_blank' rel="noreferrer">
                <img className='Contact-Logo' src='github-logo.png' alt='Github Logo'/>
            </a>
            </motion.div>
            <motion.div
        whileHover={{ rotate: 360}}
        transition={{ type: "inertia", velocity:400 }}
    >
            <a href='mailto:beraedward@gmail.com'  target='_blank' rel="noreferrer">
                <img className='Contact-Logo' src='email_icon.png' alt='Github Logo'/>
            </a>
            </motion.div>
            <motion.div
        whileHover={{ rotate: 360}}
        transition={{ type: "inertia", velocity:400 }}
    >
            <a href='tel:+263774405106' target='_blank' rel="noreferrer">
                <img className='Contact-Logo' src='phone_icon.png' alt='Github Logo'/>
            </a>
            </motion.div>
        </div>
    )
}
